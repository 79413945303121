import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  TranscriptionSpeakerParagraph
} from 'components/audio-pipeline/TranscribedText/Transcript/types'
import {
  DEFAULT_LIST_ITEM_HEIGHT,
  LIST_HEIGHT,
  LIST_SCROLL_TO_ALIGNMENT
} from 'components/audio-pipeline/TranscribedText/Transcript/constants'
import { Empty } from 'antd'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowRenderer } from 'react-virtualized'
import { SpeakerParagraph } from 'components/audio-pipeline/TranscribedText/Transcript/TranscriptParagraph'
import { TranscriptSectionHeader } from 'components/audio-pipeline/TranscribedText/Transcript/Header'
import { getTranscriptionParagraphs } from 'redux/selectors/audio-pipeline'
import debounce from 'lodash/debounce'
import styles from './styles.module.css'

export const Transcript: React.FC = () => {
  const data = useSelector(getTranscriptionParagraphs)
  const listRef = useRef<List>(null)
  const [scrollToIndex, setScrollToIndex] = useState<number>(0)
  const cache = useMemo(() => new CellMeasurerCache({
    defaultHeight: DEFAULT_LIST_ITEM_HEIGHT,
    fixedWidth: true
  }), [])

  useEffect(() => {
    cache.clearAll()
  }, [data, cache])

  const handleParagraphActive = useCallback((index: number) => {
    setScrollToIndex(index)
  }, [])

  const handleResize = useCallback(() => {
    cache.clearAll()
      listRef.current?.recomputeRowHeights()
      listRef.current?.forceUpdateGrid()
  }, [cache])

  const debouncedResize = useMemo(() => debounce(handleResize, 100), [handleResize])

  useEffect(() => {
    window.addEventListener('resize', debouncedResize)
    return () => window.removeEventListener('resize', debouncedResize)
  }, [debouncedResize])

  const renderNoData = useCallback(() =>
      <Empty className={styles.empty} />,
    [])

  const rowRenderer = useCallback<ListRowRenderer>(({ key, index, style, parent }) => {
    const {
      speaker,
      startTime,
      endTime,
      wordTimestamps
    }: TranscriptionSpeakerParagraph = data[index]

    return (
      <CellMeasurer
        cache={cache}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          // @ts-ignore
          <div ref={registerChild} style={style}>
            <SpeakerParagraph
              key={key}
              index={index}
              speaker={speaker}
              startTime={startTime}
              endTime={endTime}
              words={wordTimestamps}
              onParagraphActive={handleParagraphActive}
            />
          </div>
        )}
      </CellMeasurer>
    )

  }, [data, cache, handleParagraphActive])

  return (
    <>
      <TranscriptSectionHeader />
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            height={LIST_HEIGHT}
            width={width}
            className={styles.list}
            scrollToIndex={scrollToIndex}
            scrollToAlignment={LIST_SCROLL_TO_ALIGNMENT}
            noRowsRenderer={renderNoData}
            rowCount={data.length}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            ref={listRef}
          />
        )}
      </AutoSizer>
    </>
  )
}