import React, { useCallback, useEffect, useRef } from 'react'
import { DEFAULT_SHOULD_RENDER_CONTROLS } from 'components/common/media/Audio/constants'
import styles from './styles.module.css'

interface Props {
  id?: string
  src: string
  controls?: boolean
  currentTime?: number
  onPlay?: () => void
  onPause?: () => void
  onTimeUpdate?: (timestamp: number) => void
}

export const Audio: React.FC<Props> = ({
  id,
  src,
  controls = DEFAULT_SHOULD_RENDER_CONTROLS,
  currentTime,
  onPlay,
  onPause,
  onTimeUpdate
}) => {
  const audioRef = useRef<any>(null)

  const handleTimeUpdate = useCallback(() => {
      if (onTimeUpdate && audioRef?.current?.currentTime) {
        onTimeUpdate(audioRef.current.currentTime)
      }
    },
    [audioRef, onTimeUpdate])

  useEffect(() => {
    if (currentTime) {
      audioRef.current.currentTime = currentTime
    }
  }, [currentTime])

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    const activeElement = document.activeElement
    const isInputActive = activeElement instanceof HTMLTextAreaElement
    if (event.code === 'Space' && !isInputActive && audioRef.current) {
      event.preventDefault()
      if (audioRef.current.paused) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <audio
      id={id}
      src={src}
      ref={audioRef}
      controls={controls}
      className={styles.wrapper}
      onPlay={onPlay}
      onPause={onPause}
      onTimeUpdate={handleTimeUpdate}
    />
  )
}