import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Colors } from 'constants/common/other/colors'
import { Tooltip } from 'antd'
import { WordTooltipContent } from 'components/audio-pipeline/TranscribedText/Transcript/WordTooltipContent'
import { getEditedWordId, getEditedWords, getPlayerCurrentWordId } from 'redux/selectors/audio-pipeline'
import { setPlayerSelectedWordId } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

interface Props {
  id: string
  text: string
  startTime: number
  endTime: number
  isPassed?: boolean
  isActive?: boolean
}

export const TranscriptWord: React.FC<Props> = React.memo(({
  id,
  text,
  isPassed,
  isActive,
  startTime,
  endTime
}) => {
  const dispatch = useDispatch()
  const currentWordId = useSelector(getPlayerCurrentWordId)
  const editedWordId = useSelector(getEditedWordId)
  const editedWordsById = useSelector(getEditedWords)
  const isEditing = id === editedWordId
  const editedWord = editedWordsById[id]
  const wordText = editedWord?.text || text
  const displayText = `${wordText} `

  const handleClick = useCallback(() => {
    dispatch(setPlayerSelectedWordId(id))
  }, [id, dispatch])

  useEffect(() => {
    if (isActive && currentWordId) {
      dispatch(setPlayerSelectedWordId(null))
    }
  }, [isActive, currentWordId, dispatch])

  const isOpen = editedWordId ? isEditing : undefined

  return (
    <Tooltip
      open={isOpen}
      destroyTooltipOnHide
      color={Colors.white}
      title={
        <WordTooltipContent
          id={id}
          text={text}
          startTime={startTime}
          endTime={endTime}
        />
      }
    >
      <span
        className={classNames(styles.wrapper, {
          [styles.passed]: isPassed,
          [styles.highlighted]: isActive,
          [styles.editing]: isEditing,
          [styles.edited]: !!editedWord
        })}
        onClick={handleClick}
      >
        {displayText}
      </span>
    </Tooltip>
  )
})