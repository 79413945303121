import React from 'react'
import { useSelector } from 'react-redux'
import { WordTooltipTitle } from 'components/audio-pipeline/TranscribedText/Transcript/WordTooltipContent/Title'
import { EditWord } from 'components/audio-pipeline/TranscribedText/Transcript/WordTooltipContent/EditWord'
import { InitialWordData } from 'components/audio-pipeline/TranscribedText/Transcript/WordTooltipContent/InitialWordData'
import { getEditedWords } from 'redux/selectors/audio-pipeline'
import { formatPlaybackWordTime } from 'utils/audio-pipeline/audio-player'
import styles from './styles.module.css'

interface Props {
  id: string
  text: string
  startTime: number
  endTime: number
}

export const WordTooltipContent: React.FC<Props> = ({
  id,
  text,
  startTime,
  endTime
}) => {
  const title = `${formatPlaybackWordTime(startTime)} - ${formatPlaybackWordTime(endTime)}`
  const editedWords = useSelector(getEditedWords)
  const editedWord = editedWords[id]

  return (
    <div className={styles.wrapper}>
      <WordTooltipTitle text={title} />
      {editedWord && <InitialWordData id={id} text={text} />}
      <EditWord
        id={id}
        text={text}
        startTime={startTime}
        endTime={endTime}
      />
    </div>
  )
}