import { all } from 'redux-saga/effects'
import { auth } from 'redux/sagas/auth'
import { errors } from 'redux/sagas/errors'
import { audioPipeline } from 'redux/sagas/audio-pipeline'
import { messages } from 'redux/sagas/system-messages'

export default function* rootSaga () {
  yield all([
    auth(),
    errors(),
    audioPipeline(),
    messages()
  ])
}