import React, { useCallback, useEffect, useState } from 'react'
import { Input } from 'antd'
import { useSelector } from 'react-redux'
import { EditWordButton } from 'components/audio-pipeline/TranscribedText/Transcript/WordTooltipContent/EditButton'
import { getEditedWordId, getEditedWords } from 'redux/selectors/audio-pipeline'
import styles from './styles.module.css'

interface Props {
  id: string
  text: string
  startTime: number
  endTime: number
}

export const EditWord: React.FC<Props> = ({
  id,
  text,
  startTime,
  endTime
}) => {
  const [editedText, setEditedText] = useState<string>(text)
  const editedWordId = useSelector(getEditedWordId)
  const editedWords = useSelector(getEditedWords)
  const editedWord = editedWords[id]
  const isEditing = id === editedWordId

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedText(e.target.value)
  }, [])

  useEffect(() => {
    if (isEditing) {
      setEditedText(editedWord?.text || text)
    }
  }, [isEditing, editedWord, text])
  return (
    <div className={styles.wrapper}>
      {isEditing &&
        <Input
          className={styles.input}
          value={editedText}
          onChange={handleChange}
        />
      }
      <EditWordButton
        id={id}
        wordText={text}
        editedWordText={editedText}
        startTime={startTime}
        endTime={endTime}
      />
    </div>
  )
}